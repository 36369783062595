<template>
  <div>
    <div v-if="paymentsAllowed">
      <div v-if="order.status !== 'paid'">
        <div class="mb-2" v-if="order && order.instalments.length > 1">
          <p class="alert alert-primary">
            <i class="far fa-info-circle me-1"></i>
            This order allows payment via instalments. You can choose to pay
            either a single instalment or to pay the full order amount now.
          </p>
          <div class="row">
            <div class="col">
              <div class="form-check" v-if="nextInstalment">
                <input
                    class="form-check-input"
                    name="paymentType"
                    type="radio"
                    v-model="paymentType"
                    value="instalment"
                />
                <label class="form-check-label">
                  Pay instalment: {{order.currency.symbol}}{{nextInstalment.total | currencyFormat }}
                </label>
              </div>
              <div class="form-check" v-if="nextInstalment">
                <input
                    class="form-check-input"
                    name="paymentType"
                    type="radio"
                    v-model="paymentType"
                    value="remaining"
                />
                <label class="form-check-label">
                  Pay total outstanding amount: {{order.currency.symbol}}{{order.amount_outstanding | currencyFormat }}
                </label>
              </div>
              <p class="mt-2 mb-0" v-if="nextInstalment">
                Amount to pay now:
                <b v-if="paymentType === 'instalment'">
                  {{order.currency.symbol}}{{ this.nextInstalment.total | currencyFormat }}
                </b>
                <b v-else>
                  {{order.currency.symbol}}{{ this.order.amount_outstanding | currencyFormat }}
                </b>
              </p>
            </div>
          </div>
        </div>

        <adyen-order-payment
          v-if="clinic.has_adyen_integration"
          :clinic="clinic"
          :order="order"
          :instalment="nextInstalment"
          :payment-type="paymentType"
          @success="onSuccess"
        />
        <stripe-order-payment
          v-else-if="clinic.has_stripe_integration"
          :clinic="clinic"
          :order="order"
          :instalment="nextInstalment"
          :payment-type="paymentType"
          @success="onSuccess"
        />
      </div>
      <div class="text-center" v-else>
        <p class="mb-3">
          <i class="fa fa-check-circle me-1"></i>
          This order has already been paid.
        </p>
      </div>
    </div>

    <div v-else>
      <div class="row">
        <div class="col-md-6 mx-auto">
          <div class="card border-0">
            <div class="card-body text-center">
              <h1><i class="fa fa-exclamation-circle fa-3x text-primary"></i></h1>
              <p>Unfortunately, card payment is not available for this order.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdyenOrderPayment from "./AdyenOrderPayment"
import StripeOrderPayment from "./StripeOrderPayment"
export default {
  name: "CardPayment",
  props: ["order"],
  data() {
    return {
      clinic: this.order.clinic,
      paymentType: "instalment",
      redirectTo: "/"
    }
  },
  computed: {
    paymentsAllowed() {
      if (this.clinic.has_adyen_integration) {
        return this.clinic.payments_allowed
      } else if (this.clinic.has_stripe_integration) {
        return this.clinic.charges_enabled
      } else {
        return false;
      }
    },
    unpaidInstalments() {
      if (this.order){
        return this.order.instalments.filter((instalment) => {
          return instalment.status === "not_paid";
        });
      } else {
        return [];
      }
    },
    nextInstalment() {
      return this.unpaidInstalments[0] ?? null
    },
  },
  methods: {
    getRedirectUrl() {
      const params = new URLSearchParams(window.location.search);
      if (params.has("redirect")) {
        this.redirectTo = decodeURIComponent(params.get("redirect"));
      }
    },
    onSuccess() {
      this.$emit("success");
    }
  },
  filters: {
    currencyFormat(val) {
      return (Math.round(val * 100) / 100).toFixed(2);
    },
  },
  created() {
    this.getRedirectUrl();
  },
  components: {
    AdyenOrderPayment,
    StripeOrderPayment
  }
}
</script>

<style scoped>

</style>
