<template>
  <div>
    <div v-if="loadingCard">
      <div class="row">
        <div class="col text-center">
          <i class="far fa-spinner fa-spin fa-3x"></i>
        </div>
      </div>
    </div>
    <div :class="loadingCard ? 'd-none' : ''">
      <div class="row" v-if="order.currency_id !== 1">
        <div class="col-md-6 mx-auto">
          <div class="card border-0">
            <div class="card-body text-center">
              <h1><i class="fa fa-exclamation-circle fa-3x text-primary"></i></h1>
              <p>Unfortunately, card payment is not available for this order.</p>
            </div>
          </div>
        </div>
      </div>
      <form v-else role="form">
        <!-- Cardholder's Name -->
        <div class="row mb-4">
          <div class="col mx-auto">
            <label for="name">Cardholder's Name</label>
            <input
                type="text"
                class="form-control"
                name="name"
                v-model="name"
            />
          </div>
        </div>

        <!-- Card Details -->
        <div class="row mb-4">
          <div class="col mx-auto">
            <label for="name">Card Details</label>
            <div id="payment-card-element"></div>
          </div>
        </div>

        <hr />

        <!-- Payment Button -->
        <div class="row mb-3">
          <div class="col mx-auto text-end">
            <button
                type="submit"
                class="btn btn-primary"
                @click.prevent="processPayment"
                :disabled="busy"
            >
              <span v-if="busy">
                <i class="fa fa-btn fa-spinner fa-spin"></i> Processing
                Payment
              </span>

              <span v-else>Make Card Payment</span>
            </button>
          </div>
        </div>

        <div class="row mb-0">
          <div class="col">
            <p class="alert alert-primary">
              By clicking "Make Card Payment" you are confirming the
              payment for the above service. Once payment is processed you
              will be redirected back to
              <strong v-if="clinic">{{ clinic.name }}</strong>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ["clinic", "order", "instalment", "paymentType"],
  data() {
    return {
      name: "",
      busy: false,
      loadingCard: true,
      cardElement: null,
      stripe: null,
      paymentIntent: null,
    };
  },
  watch: {
    paymentType(newVal) {
      if (newVal === "remaining") {
        this.loadingCard = true;
        this.fetchRemainingAmountPaymentIntent().then(() => {
          this.loadingCard = false;
        });
      } else if (newVal === "instalment") {
        this.loadingCard = true;
        this.fetchInstalmentPaymentIntent().then(() => {
          this.loadingCard = false;
        });
      }
    },
  },
  methods: {
    fetchInstalmentPaymentIntent() {
      if (this.instalment) {
        return this.$axios
            .get(
                process.env.VUE_APP_API_URL +
                "/orders/instalments/" +
                this.instalment.id +
                "/payment-intent"
            )
            .then(({ data }) => {
              this.paymentIntent = data;
            });
      }
    },
    fetchRemainingAmountPaymentIntent() {
      return this.$axios
          .get(
              process.env.VUE_APP_API_URL +
              "/orders/" +
              this.order.id +
              "/remaining-amount-payment-intent"
          )
          .then(({ data }) => {
            this.paymentIntent = data;
          });
    },
    initStripe() {
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
      this.cardElement = this.createCardElement("#payment-card-element");
      this.loadingCard = false;
    },
    createCardElement(container) {
      if (!this.stripe) {
        throw "Invalid Stripe Key/Secret";
      }

      var card = this.stripe.elements().create("card", {
        style: {
          base: {
            "::placeholder": {
              color: "#aab7c4",
            },
            fontFamily:
                'Whitney, Lato, -apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji","Segoe UI Emoji", "Segoe UI Symbol"',
            color: "#495057",
            fontSize: "15px",
          },
        },
      });

      card.mount(container);
      return card;
    },
    processPayment() {
      this.busy = true;
      this.stripe
          .confirmCardPayment(this.paymentIntent.client_secret, {
            payment_method: {
              card: this.cardElement,
            },
          })
          .then((result) => {
            if (result.error) {
              this.$EventBus.$emit("alert", {
                color: "danger",
                message: result.error.message,
              });
              self.hasCardErrors = true;
              this.busy = false;
              return;
            }
            this.$emit("success");
            this.busy = false;
          });
    },
  },
  mounted() {
    this.initStripe();
    this.fetchInstalmentPaymentIntent();
  },
};
</script>

<style>
</style>
