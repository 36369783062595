<template>
  <div>
    <div v-if="order.status !== 'paid'">
      <div :class="clinic && clinic.payments_allowed ? 'd-block' : 'd-none'">
        <!-- adyen drop in payments -->
        <busy :visible="loading" />
        <div ref="dropincontainer"></div>

        <div class="ms-auto my-2 w-100 text-end">
          <a href="https://swandoola.com/terms" class="small">
            Terms & Conditions
          </a>
          <a href="https://swandoola.com/privacy" class="small ms-2">
            Privacy Policy
          </a>
        </div>

        <div class="row mb-3">
          <div class="col">
            <p class="alert alert-dark">
              By clicking "Make Card Payment" you are confirming the
              payment for the above service. Once payment is processed you
              will be redirected back to
              <strong>{{ clinic.name }}</strong>
            </p>
          </div>
        </div>
        <div v-if="clinic && !clinic.payments_allowed">
          <p class="mb-0">
            <i class="fa fa-info-circle text-info me-1"></i>
            Please refer to your appointment confirmation email for further details or refer to your invoice for payment instructions.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdyenCheckout from "@adyen/adyen-web";
import '@adyen/adyen-web/dist/adyen.css';

export default {
  props: ["clinic", "order", "instalment", "paymentType"],
  data() {
    return {
      loading: true,
      name: "",
      busy: false,
      loadingCard: true,
    };
  },
  computed: {
    unpaidInstalments() {
      if (this.order){
        return this.order.instalments.filter((instalment) => {
          return instalment.status === "not_paid";
        });
      } else {
        return [];
      }
    },
    adyenPaymentConfiguration() {
      return {
        paymentMethodsResponse: this.paymentMethods, // The `/paymentMethods` response from the server.
        clientKey: process.env.VUE_APP_ADYEN_CLIENT_ORIGIN_KEY,
        locale: "en-GB",
        environment: process.env.VUE_APP_ADYEN_ENVIRONMENT.toLowerCase(),
        onSubmit: (state, dropin) => {
          // Your function calling your server to make the `/payments` request
          this.makePayment(state.data)
              .then(response => {
                const responseData = response.data;
                if (responseData.action) {
                  // Drop-in handles the action object from the /payments response
                  dropin.handleAction(responseData.action);
                } else {
                  // Your function to show responseData final result to the shopper
                  this.showFinalResult(responseData, dropin);
                }
                this.paymentBusy = false;
              })
              .catch(error => {
                throw Error(error);
              });
        },
        paymentMethodsConfiguration: {
          card: { // Example optional configuration for Cards
            hasHolderName: true,
            holderNameRequired: true,
            enableStoreDetails: true,
            hideCVC: false, // Change this to true to hide the CVC field for stored cards
            name: 'Credit or debit card'
          }
        }
      };
    }
  },
  methods: {
    fetchPaymentMethods() {
      return this.$axios.get(process.env.VUE_APP_API_URL + "/adyen-payment-methods")
          .then(({ data }) => {
            this.paymentMethods = data;
            this.loadingPaymentMethods = false;
          });
    },
    initAdyenDropinComponent() {
      const checkout = new AdyenCheckout(this.adyenPaymentConfiguration);
      const dropin = checkout.create('dropin').mount(this.$refs.dropincontainer);
    },
    makePayment(data) {
      //Handle 'then' in adyenPaymentConfiguration computed property methods
      if (!this.paymentBusy){
        this.paymentBusy = true;

        return this.$axios.post(process.env.VUE_APP_API_URL + "/orders/api/" + this.order.id + "/adyen-make-payment",
            {
              paymentState: data,
              paymentType: this.paymentType,
              instalment: this.instalment
            }
        );
      } else {
        return null;
      }
    },
    showFinalResult(data, dropin) {
      const result = data.payment;
      switch (result.resultCode) {
        case 'Authorised':
          dropin.setStatus('success', { message: 'Payment successful!' });
          this.$emit("success");

          break;
        case 'Pending':
          dropin.setStatus('success', { message: 'We have received your order and are awaiting payment to be completed. You will receive final result of the payment shortly.' });
          break;
        case 'Received':
          dropin.setStatus('success', { message: 'We have received your order and are awaiting payment to be completed. You will receive final result of the payment shortly.' });
          break;
        case 'Refused':
          dropin.setStatus('error', { message: result.refusalReason });
          setTimeout(function () {
            dropin.setStatus('ready');
          }, 3000);
          break;
        case 'Error':
          dropin.setStatus('error', { message: result.refusalReason });
          setTimeout(function () {
            dropin.setStatus('ready');
          }, 3000);
          break;
      }
    },
  },
  created() {
    const vm = this;
    setTimeout(function () {
      vm.fetchPaymentMethods().then( () => {
        vm.loading = false;
        vm.initAdyenDropinComponent();
      })
    }, 4000);
  },
};
</script>

<style>
</style>
